<section class="hero is-danger is-bold">
  <div class="hero-body">
    <div class="container">
      <h1 class="title futura">
        PARTY ROLL
      </h1>
      <h2 class="subtitle">
        Who gave us mics?
      </h2>
      <h3 class="social-media-links">
        <span aria-label="Subscribe to the rss feed" class="icon tooltip has-tooltip" data-tooltip="RSS feed">
          <a href="http://partyroll.libsyn.com/rss">
            <fa-icon [icon]="faRss"></fa-icon>
          </a>
        </span>
        <span aria-label="Follow us on Twitter" class="icon tooltip has-tooltip" data-tooltip="Twitter">
          <a href="https://twitter.com/Party_Roll">
            <fa-icon [icon]="faTwitter"></fa-icon>
          </a>
        </span>
        <span aria-label="Like us on Facebook" class="icon tooltip has-tooltip" data-tooltip="Facebook">
          <a href="https://www.facebook.com/PartyRollPodcast/">
            <fa-icon [icon]="faFacebook"></fa-icon>
          </a>
        </span>
        <span aria-label="Subscribe to our YouTube show" class="icon tooltip has-tooltip" data-tooltip="Youtube">
          <a href="https://youtube.com/@PartyRollPodcast">
            <fa-icon [icon]="faYoutube"></fa-icon>
          </a>
        </span>
        <span aria-label="Subscribe to our Patreon" class="icon tooltip has-tooltip" data-tooltip="Patreon">
          <a href="https://www.patreon.com/partyrollpodcast">
            <fa-icon [icon]="faPatreon"></fa-icon>
          </a>
        </span>
      </h3>
    </div>
  </div>
</section>
<nav class="tabs is-centered is-large">
  <ul>
    <li routerLinkActive="is-active">
      <a [routerLink]="['/about']">About</a>
    </li>
    <li routerLinkActive="is-active">
      <a [routerLink]="['/seasons']">Seasons</a>
    </li>
    <li routerLinkActive="is-active">
      <a [routerLink]="['/episodes']" [queryParams]="{show: 'all', season: 'all'}">Episodes</a>
    </li>
    <li routerLinkActive="is-active">
      <a [routerLink]="['/support']">Be Cute
        <fa-icon [icon]="faHeart" class="p-l-5"></fa-icon>
      </a>
    </li>
    <li routerLinkActive="is-active">
      <a [routerLink]="['/contact']">Social</a>
    </li>
  </ul>
</nav>
<router-outlet></router-outlet>
<footer class="footer">
  <div class="container">
    <div class="content has-text-centered">
      <p>
        <strong>© 2014-2023 Party Roll</strong> by
        <span class="matt has-text-danger">M<fa-icon [icon]="faA" [spin]="true"></fa-icon>tt</span>
        <span class="is-pulled-right has-text-grey-light version">{{appVersion}}</span>
      </p>
    </div>
  </div>
</footer>
