<div class="columns">
  <div class="column is-4-full-hd is-offset-4-full-hd is-6-widescreen is-offset-3-widescreen is-8-desktop is-offset-2-desktop is-12-mobile">
    <section class="section">
      <div class="content">
        <h1 class="title">Support Our Show!</h1>
        If you like what you hear, please consider supporting our show! We’ll be making various methods available to
        support us by as time goes on, but what we have so far is listed below.

        <h2>Patreon</h2>
        We have a patreon that you can use to support us on a monthly basis. Go to the link to learn more information!
        <br>
        <a class="button is-danger" href="https://www.patreon.com/partyrollpodcast" target="_blank">
          <fa-icon [icon]="faPatreon"></fa-icon>&nbsp;Become a Patron
        </a>

        <h2>iTunes</h2>
        As always, the easiest way to support us is to rate us on <a
        href="https://itunes.apple.com/us/podcast/party-roll-dnd-actual-play/id953920832?mt=2"
        target="_blank">iTunes</a> It takes a minute or so and helps us reach more like-minded d20 hunks/hunketts like
        yourself.

        <!--<h2>Donate</h2>
        If you want to just throw a little bit of money our way, we have just the option for you:

        <button class="button is-warning" alt="PayPal - The safer, easier way to pay online!" (click)="postPaypal()">
          <fa-icon [icon]="faPaypal"></fa-icon>&nbsp;Donate
        </button>
        <img src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" alt="" width="1" height="1" border="0"/>
-->
      </div>
    </section>
  </div>
</div>

