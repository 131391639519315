<div class="columns">
  <div class="column is-10 is-offset-1">
    <section class="section">
      <div class="content has-text-centered is-size-5">
        <figure class="image is-7by1">
          <img alt="Logos" src="assets/logos@0.5x.png">
        </figure>
        <div
          class="column is-4-full-hd is-offset-4-full-hd is-6-widescreen is-offset-3-widescreen is-8-desktop is-offset-2-desktop is-12-mobile">
          <h1>100% Cream, 100% Dream</h1>
          <p>Party Roll is a Dungeons and Dragons Actual Play podcast started by a bunch of friends in late 2014.</p>
          <p>The group had been playing Pathfinder and D&D for several years before they had the collective idea of:
            "What
            if we could record these Cory-giggles and share them with the world?"</p>
          <p>The Result is this! Mark, Steven, Cory, Dusty, Rory, Matt, Vanessa, and Jose have since spent their time
            having fun gathering around the table and exploring the various worlds created by our DMs. A <a
              href="https://www.youtube.com/watch?v=da5NXkIi0Xg" target="_blank">lot</a> has
            <abbr class="tooltip has-text-info has-tooltip-multiline"
                  data-tooltip="4 weddings, 6 children, 5 recording spaces, 1 cross-country move, 1 batknife">changed</abbr>
            since we started this podcast, but D&D
            <a href="https://www.dandwiki.com/wiki/Undeath_(5e_Subclass)" target="_blank">never dies.</a>
          </p>
          <h2>Other Projects</h2>
          <div class="columns">
            <div class="column is-6">
              <div class="card">
                <div class="card-content">
                  <h4>
                    <a href="https://youtube.com/@TeenerTime" target="_blank">Teener Time</a>
                  </h4>
                  <div class="content">
                    We've also come together in our spare time to record ourselves playing video games of various sorts
                    under the YouTube show <a href="https://www.youtube.com/channel/UCk4oPeLpTjDvI-gi38GITSA"
                                              target="_blank">Teener Time</a>. We don't update as often as we like, but
                    if you like video games, you should check us out.
                  </div>

                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="card">
                <div class="card-content">
                  <h4>
                    <a href="https://www.youtube.com/@aftermarkgaming" target="_blank">AfterMark Gaming</a>
                  </h4>
                  <div class="content">
                    Watch Mark sample his entire gaming backlog. Uploaded at 4k60FPS without commentary.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
