<div *ngIf="getEpisodeInfo(podcastData | async)" class="columns">
  <div class="column is-10 is-offset-1">
    <div class="columns p-t-20">
      <div class="column is-6-desktop is-offset-3-desktop is-10-mobile is-offset-1-mobile">
        <div class="card">
          <div class="card-image">
            <figure class="image">
              <video controls poster="{{podcastItem.itunesImage}}" preload="metadata">
                <source src="{{podcastItem.link}}" type="audio/mpeg">
                Your browser does not support the audio element.
              </video>
            </figure>
          </div>
          <div class="card-content">
            <div class="content">
              <h2 class="title">{{podcastItem.title}}</h2>
              <small [title]="podcastItem.pubDate">{{podcastItem.pubDate | amTimeAgo}}</small>&nbsp;
              <small title="Download Episode">
                <a [href]="podcastItem.link" target="_blank">
                  <fa-icon [icon]="faCloudDownloadAlt"></fa-icon>
                  Download
                </a>
              </small>
              <div [innerHtml]="podcastItem.description"></div>
            </div>
          </div>
          <footer class="card-footer">
            <a *ngIf="podcastItem.prevEpisode" class="card-footer-item" href="/episode/{{podcastItem.prevEpisode}}"
               title="Previous Episode">
              <fa-icon [icon]="faArrowLeft" class="p-r-5"></fa-icon>
              {{podcastItem.prevEpisodeTitle}}
            </a>
            <a *ngIf="podcastItem.nextEpisode" class="card-footer-item" href="/episode/{{podcastItem.nextEpisode}}"
               title="Next Episode">
              {{podcastItem.nextEpisodeTitle}}
              <fa-icon [icon]="faArrowRight" class="p-l-5"></fa-icon>
            </a>
          </footer>
        </div>
      </div>
    </div>
    <div class="columns p-t-20">
      <div class="column is-10-mobile is-offset-1-mobile">
        <disqus [identifier]="(podcastItem.title | permalinkDisqus)"></disqus>
      </div>
    </div>
  </div>
</div>

