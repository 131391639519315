<div class="columns">
  <div class="column is-4-full-hd is-offset-4-full-hd is-6-widescreen is-offset-3-widescreen is-8-desktop is-offset-2-desktop is-12-mobile filters">
    <span class="pr-1">Show me:</span>
    <div class="select">
      <select [(ngModel)]="showTypeFilter" (ngModelChange)="onShowChange()">
        <option *ngFor="let showFilter of showFilterMap | keyvalue"
                [value]="showFilter.key">{{showFilter.value}}</option>
      </select>
    </div>
    <div class="select" *ngIf="availableSeasons.length > 0">
      <select [(ngModel)]="seasonFilter" (ngModelChange)="onFiltersChanged(true)">
        <option value="all">All Seasons</option>
        <option *ngFor="let season of availableSeasons" [value]="season">Season {{season}}</option>
      </select>
    </div>
    <div class="select">
      <select [(ngModel)]="sortOrder" (ngModelChange)="onSortChanged()">
        <option value="-1">Newest First</option>
        <option value="1">Oldest First</option>
      </select>
    </div>
  </div>
</div>
<ng-container *ngFor="let item of filteredEpisodes | orderBy: sortOrderMap[sortOrder] | paginate: config">
  <prp-blurb-box [item]="item"></prp-blurb-box>
</ng-container>
<div class="columns pb-3 sticky-bottom">
  <div class="column is-4-full-hd is-offset-4-full-hd is-6-widescreen is-offset-3-widescreen is-8-desktop is-offset-2-desktop is-12-mobile">
    <div class="card">
      <div class="card-content">
        <pagination-template #p="paginationApi"
                             [id]="config.id"
                             (pageChange)="onPageChange($event)"
                             (pageBoundsCorrection)="onPageChange($event)">
          <nav class="pagination is-centered" role="navigation" aria-label="pagination">
            <a class="pagination-previous" [class.is-disabled]="p.isFirstPage()" (click)="p.previous()">Previous</a>
            <a class="pagination-next" [class.is-disabled]="p.isLastPage()" (click)="p.next()">Next</a>
            <ul class="pagination-list">
              <li *ngFor="let page of p.pages">
                <a class="pagination-link" [class.is-current]="p.getCurrent() === page.value"
                   (click)="p.setCurrent(page.value)">
                  <span>{{ page.label }}</span>
                </a>
              </li>
            </ul>
          </nav>
        </pagination-template>
      </div>
    </div>
  </div>
</div>

