<div class="columns">
  <div class="column is-4-full-hd is-offset-4-full-hd is-6-widescreen is-offset-3-widescreen is-8-desktop is-offset-2-desktop is-12-mobile">
    <div class="box">
      <article class="media">
        <div class="media-left">
          <figure class="image is-128x128">
            <img [src]="item.itunesImage">
          </figure>
        </div>
        <div class="media-content">
          <div class="content">
            <div class="pos-relative">
              <p>
                <strong><a routerLink="/episode/{{item.title | permalink}}">{{item.title}}</a></strong>&nbsp;
                <small [title]="item.pubDate">{{item.pubDate | amTimeAgo}}</small>&nbsp;
                <br>
              </p>
              <div [innerHtml]="item.description" [class.collapsed]="collapsed"></div>
              <span class="mask" *ngIf="collapsed"></span>
            </div>
          </div>
        </div>
      </article>
    </div>
  </div>
</div>