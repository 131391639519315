<div class="columns">
  <div class="column is-4-full-hd is-offset-4-full-hd is-6-widescreen is-offset-3-widescreen is-8-desktop is-offset-2-desktop is-12-mobile">
    <section class="section">
      <div class="content">
        <h2>Social Media</h2>
        <h4>Party Roll</h4>
        <div><strong>Email:</strong> <span class="email p-l-5">@</span></div>
        <div><strong>Twitter:</strong> <a class="p-l-5" href="https://twitter.com/Party_Roll">@Party_Roll</a></div>
        <div><strong>Facebook:</strong> <a class="p-l-5" href="https://www.facebook.com/PartyRollPodcast/">Party Roll
          Podcast</a></div>
        <br>
        <h4>People</h4>
        <div><strong>Mark:</strong> <a class="p-l-5" href="https://twitter.com/elmoogle" target="_blank">@Elmoogle</a>
        </div>
        <div><strong>Steven:</strong> <a class="p-l-5" href="https://twitter.com/PartyRollSteven" target="_blank">@PartyRollSteven</a>
        </div>
        <div><strong>Dusty:</strong> <a class="p-l-5" href="https://twitter.com/PartyRollDusty" target="_blank">@PartyRollDusty</a>
        </div>
        <div><strong>Cory:</strong> <a class="p-l-5" href="https://twitter.com/Cory_PartyRoll" target="_blank">@Cory_PartyRoll</a>
        </div>
        <div><strong>Vanessa:</strong> <a class="p-l-5" href="https://twitter.com/Muhnasis"
                                          target="_blank">@Muhnasis</a></div>
        <div><strong>Jose:</strong> 🤷</div>
        <div><strong>Matt:</strong> <a class="p-l-5" href="https://twitter.com/ballofmatts"
                                       target="_blank">@ballofmatts</a></div>
        <div><strong>Rory:</strong> 🤷</div>

      </div>
    </section>
  </div>
</div>
