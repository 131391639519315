<ng-container *ngFor="let category of seasonData">
  <div class="columns">
    <div class="column is-4-full-hd is-offset-4-full-hd is-6-widescreen is-offset-3-widescreen is-8-desktop is-offset-2-desktop is-12-mobile">
      <section class="hero is-info is-bold">
        <div class="hero-body">
          <div class="container">
            <h1 class="title">
              {{category.categoryName}}
            </h1>
          </div>
        </div>
      </section>
    </div>
  </div>
  <div class="columns" *ngFor="let season of category.seasons">
    <div class="column is-4-full-hd is-offset-4-full-hd is-6-widescreen is-offset-3-widescreen is-8-desktop is-offset-2-desktop is-12-mobile">
      <div class="card">
        <div class="card-content">
          <div class="media">
            <div class="media-content">
              <p class="title is-4">{{season.title}}</p>
              <p class="subtitle is-6">{{season.subtitle}}</p>
              <p class="is-italic">{{season.tagLine}}</p>
            </div>
          </div>
          <div class="card-image" *ngIf="!!season.image">
            <figure class="image is-2by1">
              <img [src]="season.image.url">
            </figure>
          </div>

          <div class="content">
            <div><strong>System:</strong> {{season.system}}</div>
            <div><strong>{{season.credit.label}}:</strong> {{season.credit.data}}</div>
            <h3>Starring</h3>
            <div *ngFor="let player of season.players">
              <strong>{{player.character}}</strong> – {{player.name}}
            </div>
          </div>
        </div>
        <footer class="card-footer">
          <a class="card-footer-item" href="{{season.episodeURL}}" *ngIf="season.episodeURL">
            <fa-icon [icon]="faHeadphones" class="p-r-5"></fa-icon> Listen to {{season.title}} Now
          </a>
        </footer>
      </div>
    </div>
  </div>
</ng-container>
